import type { FC } from 'react'
import NextImage, { type ImageProps, type ImageLoader } from 'next/image'

import { log } from '@utils/logger'
import { graphcmsImageLoader } from '../utils/image'
import Config from '../config'

const Image: FC<ImageProps> = (props) => {
  let loader: ImageLoader | undefined

  if (String(props.src).startsWith(Config.graphcmsCdnUrl)) {
    loader = graphcmsImageLoader
  }

  return <NextImage loader={loader} {...props} />
}

export { Image }
