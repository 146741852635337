import type { FC, AnchorHTMLAttributes } from 'react'
import NextLink, { LinkProps } from 'next/link'
import styled from '@emotion/styled'

import { Icon } from './Icon'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

const StyledLink = styled(NextLink)`
  display: flex;
  align-items: center;
  gap: 0.3em;
  inline-size: max-content;

  svg {
    block-size: 1.3em;
    padding: 0.1em;
    inline-size: auto;
    background-color: rgb(var(--color-primary-50));
    color: rgb(var(--color-background-400));
    border-radius: 1px;
    transition: transform 100ms ease-in;
    transform: translate3d(0, 0, 0);
  }

  &:hover,
  &:focus-visible {
    // color: rgb(var(--color-primary-300));

    svg {
      background-color: rgb(var(--color-primary-300));
      transform: translate3d(-4px, 0, 0);
    }
  }
`

type Props = AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkProps & {
    icon?: string
    href: string
  }

const Link: FC<Props> = ({ children, icon, ...otherProps }) => (
  <StyledLink {...otherProps}>
    {icon ? <Icon icon={icon as IconProp} /> : null}
    {children}
  </StyledLink>
)

export default Link
