import styled from '@emotion/styled'
import Link from 'next/link'

/**
 * TODO refactor this entire module as convoluted and haphazard
 * ideas:
 *  - use grid and switch grids using container queries to layout
 *  - fix grid height and use orientation query to switch portrait/landscape
 *
 *  TODO extract CardGrid and CardWrapper
 */

export const CardExcerpt = styled.div`
  --line-clamp: 5;

  display: none;
  font-size: clamp(var(--font-text-xs), 10cqi - 2vw, var(--font-text-s));

  p {
    line-height: 1.6;
  }

  @supports (-webkit-line-clamp: 1) {
    p {
      display: -webkit-box;
      -webkit-line-clamp: var(--line-clamp);
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`

export const CardImage = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: 'overlap';
  border-radius: inherit;
  block-size: 40cqi;
  overflow: hidden;

  // TODO Set up the theme's greyscale
  outline: 1px solid rgb(var(--color-shadow) / 50%);
  background-color: rgb(var(--color-shadow) / 25%);

  ${CardExcerpt} {
    display: flex;
    align-self: flex-end;
    border-radius: inherit;
    grid-area: overlap;
    block-size: 100%;
    inline-size: 100%;
    backdrop-filter: grayscale(75%) contrast(80%) blur(2px);
    background-color: rgb(var(--color-background-900) / 75%);
    color: rgb(var(--color-text-50));
    transition: opacity 110ms ease-out;
    align-items: center;
    padding: 5cqi;
    text-wrap: balance;

    @supports (text-wrap: balance) {
      text-align: center;
    }

    p {
      line-height: 1.5;
    }

    @media (hover: hover) and (pointer: fine) {
      opacity: 0;
    }
  }

  @container (min-width: 400px) {
    inline-size: 33cqi;
    block-size: revert;
  }

  img {
    grid-area: overlap;
    object-fit: cover;
    min-inline-size: 100%;
    min-block-size: 100%;
    border-radius: inherit;
    aspect-ratio: 16/6;
    filter: revert;
  }
`

export const CardContent = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 0.3em;
  padding-block: 0.6em 0.8em;
  padding-inline: 5cqi;

  ${CardExcerpt} {
    flex-grow: 1;
    display: none;
    font-size: var(--font-text-xs);
  }
`

export const CardDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-self: flex-end;
  font-size: var(--font-text-xs);
  font-weight: 300;
  color: rgb(var(--color-text-600));
  column-gap: 0.6em;

  span {
    display: flex;
    align-items: center;
    column-gap: 0.3em;

    svg {
      width: 1em;
    }
  }
`

export const CardLink = styled(Link)`
  font-family: var(--font-heading);
  font-size: clamp(var(--font-heading-xs), 17cqi - 2vw, var(--font-heading-s));
  font-weight: 500;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-wrap: balance;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: ' ';
  }

  &:is(:hover, :focus-within) {
    text-decoration: underline;
    outline: none;
  }
`

export const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  border-radius: 1em;
  transition: transform 150ms ease-out;
  block-size: 100%;
  inline-size: 100%;

  &:hover, &:has(:focus-visible) {
    --shadow-depth: clamp(0.1em, 0.25cqi, 0.3em);

    box-shadow: var(--shadow-depth) var(--shadow-depth)
      calc(var(--shadow-depth) * 2) 0 rgb(var(--color-shadow) / 35%);
    transform: scale3d(1, 1.01, 1) translateY(-5px);
    background-color: rgb(var(--color-background-100) / 100%);

    ${CardImage} {
      outline: none;
      border-end-start-radius: 0;
      border-end-end-radius: 0;
    }
  }

  // TODO enable again once it supports excerpt card image overlay
  @container (min-width: 400px) {
    ${CardContent} {
      ${CardExcerpt} {
        --line-clamp: 3;

        display: flex;
      }
    }

    flex-direction: row;

    ${CardImage} {
      --shadow-depth: 0.25cqi;

      ${CardExcerpt} {
        display: none;
      }
    }

    &:hover, &:has(:focus-visible) {
      --shadow-depth: 0.3cqi;

      ${CardImage} {
        border-end-start-radius: inherit;
        border-end-end-radius: 0;
        border-start-start-radius: inherit;
        border-start-end-radius: 0;
      }
    }
  }

  &:has(${CardLink}:is(:hover, :focus-visible)) {
    // TODO sort out convoluted logic

    ${CardImage} {
      > div {
        opacity: 1;
      }
    }
  }
`

export const CardGrid = styled.ul<{ minColSize?: string; maxColSize?: string }>`
  --min-col-size: ${({ minColSize }) => minColSize ?? '30ch'};
  --max-col-size: ${({ maxColSize }) => maxColSize ?? '50ch'};

  display: grid;
  flex-direction: column;
  padding-block: 1em;
  padding-inline: 0;
  list-style-type: none;
  grid-template-columns: repeat(auto-fit, minmax(var(--min-col-size), 1fr));
  justify-items: center;
  justify-content: center;
  place-items: center;
  margin: 0 auto;
`

export const CardWrapper = styled.li`
  contain: layout;
  container-type: inline-size;
  container-name: slot;
  padding: 1em;
  display: flex;
  inline-size: clamp(var(--min-col-size), 100%, var(--max-col-size));
  block-size: 100%;
`
