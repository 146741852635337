const locales: Record<string, string> = {
  en: 'en-gb',
}

export const formatDate = (
  date: string,
  locale = 'en',
  options?: Intl.DateTimeFormatOptions
) => {
  const defaultOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }

  const parsedDate = new Date(date)
  if (isNaN(parsedDate.getTime())) {
    throw new Error('Invalid date value')
  }

  return new Intl.DateTimeFormat(
    locales[locale] ?? locale,
    options ?? defaultOptions
  ).format(parsedDate)
}
