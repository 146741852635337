import type { FC } from 'react'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'

import { Image } from '@components/Image'
import type { Post, Asset, Rubrique, ReadingTime } from '@graphql/generated'
import { formatDate } from '@utils/i18n'
import {
  Card,
  CardImage,
  CardContent,
  CardLink,
  CardDetails,
  CardExcerpt,
} from '@components/Card'
import useTranslation from 'next-translate/useTranslation'
import { Icon } from '@components/Icon'
import type { ImageProps } from 'next/image'

const SubHeading = styled.h2`
  text-transform: uppercase;
  color: rgb(var(--color-primary-300) / 60%);
  padding: 0;
  margin: 0;
  font-size: 0.9em;
`

interface BlogCardProps
  extends Pick<Post, 'slug' | 'title' | 'excerpt' | 'publishedAt'> {
  className?: string
  rubrique?: Pick<Rubrique, 'name'> | null
  heroImage?: Pick<Asset, 'url' | 'dataURL'> | null
  imageLoading?: ImageProps['loading']
  readingTime: Pick<ReadingTime, 'minutes'>
}

export const BlogCard: FC<BlogCardProps> = ({
  className,
  excerpt,
  heroImage,
  imageLoading,
  publishedAt,
  readingTime,
  rubrique,
  slug,
  title,
}) => {
  const { t } = useTranslation('blog')
  const { locale } = useRouter()

  const publishDate = formatDate(publishedAt, locale)
  const localisedReadingTime = t('reading_time', {
    count: Math.ceil(readingTime.minutes!),
  })

  return (
    <Card className={className} aria-label={title}>
      <CardImage>
        {heroImage ? (
          <Image
            src={heroImage.url}
            alt=""
            width={250}
            height={250}
            placeholder="blur"
            blurDataURL={heroImage.dataURL}
            sizes="20vw"
            loading={imageLoading}
          />
        ) : null}
        <CardExcerpt>
          <p>{excerpt}</p>
        </CardExcerpt>
      </CardImage>
      <CardContent>
        {rubrique ? <SubHeading>{rubrique.name}</SubHeading> : null}
        <CardLink href={`/blog/${slug}`}>{title}</CardLink>
        <CardExcerpt>
          <p>{excerpt}</p>
        </CardExcerpt>
        <CardDetails>
          <span>
            <Icon icon={['far', 'calendar']} fixedWidth listItem />
            <time>{publishDate}</time>
          </span>
          <span>
            <Icon icon={['far', 'clock']} fixedWidth listItem />
            {localisedReadingTime}
          </span>
        </CardDetails>
      </CardContent>
    </Card>
  )
}
