import type { FC } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { log } from '@utils/logger'
import Config from '../config'
import React from 'react'

type SEOProps = {
  title?: string
  description?: string | null
  image?: string
  currentLocales?: string[]
  type?: 'website' | 'article'
}

const SEO: FC<SEOProps> = ({
  title,
  description,
  image,
  currentLocales,
  type,
}) => {
  const { defaultLocale, asPath, locales: defaultLocales, locale } = useRouter()

  const {
    siteUrl,
    seo: { titleTemplate, defaultTitle, defaultDescription, facebookAppId },
    socials: { mastodon, twitter },
  } = Config

  // log.info('received image for page', { image })

  // TODO add canonical for non translated pages
  // log.info('received locale for page', { locale })

  const locales = currentLocales ?? defaultLocales
  const pageTitle = titleTemplate.replace('%s', title ?? defaultTitle)
  const pageDescription = description ?? defaultDescription
  const pageType: SEOProps['type'] = type ?? 'website'

  return (
    <Head>
      <link rel="me" href={`https://fosstodon.org/@${mastodon}`} />
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      <meta property="og:type" content={pageType} />
      <meta property="og:url" content={asPath} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      {/* {image && <meta property="og:image" content={image} />} */}
      <meta property="fb:app_id" content={facebookAppId} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitter} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />

      <link
        rel="alternate"
        type="application/rss+xml"
        title="RSS 2 Feed for ornous.vercel.app"
        href="/feed"
      />

      <link
        rel="alternate"
        type="application/atom+xml"
        title="Atom Feed for ornous.vercel.app"
        href="/atom.xml"
      />

      <link
        rel="alternate"
        type="application/feed+json"
        title="JSON Feed for ornous.vercel.app"
        href="/feed.json"
      />

      {/* {image && <meta name="twitter:image" content={image} />} */}

      {locales?.map((locale) => {
        const localizedSiteUrl =
          defaultLocale === locale ? siteUrl : `${siteUrl}/${locale}`

        return (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={localizedSiteUrl + asPath}
          />
        )
      })}
    </Head>
  )
}

export default React.memo(SEO)
